<template>
  <div>
    <div class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-3">
              <label class="align-middle">Supplier</label>
              <v-select
                placeholder="Select Supplier"
                v-model="formData.supplier_id"
                :options="contacts"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
            <div v-if="!isPOCreate" class="col-sm-3">
              <label class="align-middle form-label">PO No</label>
              <input type="text" class="form-control" :value="formData.po_number" readonly>
            </div>
            <div class="col-sm-3">
              <label class="align-middle form-label">PO Date</label>
              <input type="date" class="form-control date-mask" v-model="formData.po_date" :disabled="!shouldSave">
            </div>
            <div class="col-sm-3">
              <label class="form-label">Business Unit</label>
              <v-select
                placeholder="Select Business"
                v-model="formData.business_id"
                :options="business"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-3">
              <label class="form-label">Cost Centre</label>
              <v-select
                placeholder="Select Cost Centre"
                v-model="formData.cost_centre_id"
                :options="costCentres"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
            <div class="col-sm-3">
              <label class="form-label">Project</label>
              <v-select
                placeholder="Select Project"
                v-model="formData.project_id"
                :options="projects"
                label="name"
                :reduce="name => name.id"
                :disabled="!shouldSave"
              />
            </div>
            <div class="col-sm-3">
              <label class="align-middle form-label">Req Reference</label>
              <input type="text" class="form-control" :disabled="!shouldSave">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-2">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
          </li>
          <li @click="formData.has_item_detail = false" class="nav-item">
            <a :class="{'active' : !formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
          </li>
        </ul>
      </div>

      <div class="px-3">
        <div v-if="!formData.has_item_detail">
          <template :key="index" v-for="(data, index) in accountsDetails">
            <ItemDetailsNewPO
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="isItem"
              :isPO="true"
              :isPOCreate="isPOCreate"
              :isDisable="!shouldSave"
              @onClose="removeBill"
            />
          </template>
        </div>

        <div v-else>
          <template :key="index" v-for="(data, index) in itemsDetails">
            <ItemDetailsNewPO
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="isItem"
              :isPO="true"
              :isDisable="!shouldSave"
              :isPOCreate="isPOCreate"
              @onClose="removeBill"
            />
          </template>
        </div>
      </div>

      <div class="px-2 mt-2">
        <button @click="addNewBill" class="btn btn-primary" :disabled="!shouldSave">Add line</button>
      </div>

      <div class="px-2 mb-3">
        <div class="row mt-3 px-2 justify-content-end">
          <div class="col-md-5">
            <div class="row mt-1">
              <div class="col-md-4 align-middle">SubTotal :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="subTotal" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(+) VAT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="totalVAT" readonly>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-md-4 align-middle">Total :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="total" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(-) Discount / Round up Adj :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.adjustment_amount"  >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(+) Carrying Cost :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.carrying_cost"  >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(+) TDS :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.tds"  >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">NET PAYABLE AMOUNT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model.trim="grandTotal" readonly>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-1 d-flex justify-content-end" v-if="(!poEditData.grn_general_count)">
          <div class="col-2">
            <label for="">&nbsp;</label>
            <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="savePO()"
              :disabled="disableSave || !shouldSave"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
            >Save
            </button>
          </div>
          <div class="col-2" v-if="formData.status && formData.status !== 'pending'">
            <label for="">&nbsp;</label>
            <button
              @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)"
              class="btn btn-warning waves-effect waves-float waves-light form-control"
              style="min-width: 64px;margin-top: 1rem;"
            >
              Show More
            </button>
          </div>
        </div>
      </div>
    </div>
    <view-approval-matrix-details
      :workflowDetails="workflowDetails"
      :approvalLogs="formData.workflow_approval_logs ?? []"
      ref="viewApprovalMatrix"
    />
    <GlobalLoader/>
  </div>
</template>


<script setup>
import TitleButton from '@/components/atom/TitleButton'

import {
  inject,
  ref,
  onMounted,
  computed,
  watch
}                         from 'vue';
import {
  useRoute,
  useRouter
}                         from "vue-router";
import {useStore}         from 'vuex';
import handleRequisitions from "@/services/modules/procurement/requisition";
import handlePurchase     from '@/services/modules/purchase'
import handleContact      from '@/services/modules/contact'
import ItemDetailsNewPO   from '@/components/molecule/procurement/purchase-order/purchase-order-entry/ItemDetailsNewPO.vue'
import {vatRate}          from '@/data/inventory.js'
import handleCBusinesses  from '@/services/modules/businesses'
import handleCostCentres  from "@/services/modules/ngo/costCentre";
import handleProjects     from "@/services/modules/procurement/project";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import {ca} from "date-fns/locale";

const router      = useRouter()
const route       = useRoute()
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()

const workflowDetails = ref({});
const viewApprovalMatrix = ref(null);


const {fetchProductList, fetchAccountHead, fetchCurrencyList, ...rest} = handlePurchase()
const {
    fetchPONumber,
    fetchSinglePO,
    createNewPO,
    updatePOItem,
} = handleRequisitions();
const {fetchContactProfiles} = handleContact()
const {fetchWorkflowFromPageId} = handleWorkflow()

const companyId   = computed(() => {
  return route.params.companyId
})
const {fetchBusinessList} = handleCBusinesses()
const { fetchCostCentreList } = handleCostCentres()
const { fetchProjects } = handleProjects()
let headerTitle   = ref('Create Purchase Order')
let loader        = ref(false)
let productLoader = ref(false)
let disableSave   = ref(false)
let products      = ref([])
let currencies    = ref([])
let accountHeads  = ref([])
let requisition   = ref({})
let supplier      = ref(null)
let user          = ref(null)
let poEditData    = ref({})
let business      = ref([])
let costCentres   = ref([])
let projects      = ref([])
let grandTotal = ref(null);
let adjustmentTotal = ref(null);

let isPOCreate    = true
let poData        = {
  general: [],
}
let formData      = ref({
  supplier_id        : null,
  po_number          : null,
  po_date            : null,
  requisition_details: [],
  subtotal_amount    : 0.00,
  vat_amount         : 0.00,
  total_amount       : 0.00,
  has_item_detail    : true,
  description        : '',
  adjustment_amount: 0,
  carrying_cost: 0,
  tds: 0,
  item_details       : [
    {
      product_id  : null,
      quantity    : null,
      currency    : 'BDT',
      rate        : null,
      amount      : null,
      vat         : 0,
      vat_amount  : 0,
      total_amount: null,
      description : null,
      discount_amount: 0,
      discount_percent: 0,
    }
  ],
  account_details    : [
    {
      account_head_id: null,
      currency       : 'BDT',
      amount         : null,
      vat            : 0,
      vat_amount     : 0.00,
      total_amount   : null,
      description    : null,
    }
  ],
})
let contacts                 = ref([])


const hasItemDetails = computed(() => {
  return formData.value.has_item_detail
})

watch(hasItemDetails, (newValue) => {
    if(!newValue) {
        delete formData.value.item_details
        formData.value.account_details = [{
            account_head_id: null,
            currency       : 'BDT',
            amount         : null,
            vat            : 0,
            vat_amount     : 0,
            total_amount   : null,
            description    : null,
        }]
    }
    if(newValue) {
        delete formData.value.account_details
        formData.value.item_details = [{
            product_id  : null,
            quantity    : null,
            currency    : 'BDT',
            rate        : null,
            amount      : null,
            vat         : 0,
            vat_amount  : 0,
            total_amount: null,
            description : null,
        }]
    }
})

function onPageReload() {
  loader.value         = true
  const productRes     = fetchProductList(getQuery())
  const accountHeadRes = fetchAccountHead(getQuery())
  const companyQuery = `?company_id=${companyId.value}`
  const projectQuery = companyQuery + '&with_donors=1';
  const contactQuery = companyQuery + '&type=supplier'
  const contactRes   = fetchContactProfiles(contactQuery)
  const currencyRes  = fetchCurrencyList(getQuery())
  const businessRes = fetchBusinessList(companyQuery)
  const costCenterRes = fetchCostCentreList(companyQuery)
  const projectRes = fetchProjects(projectQuery)
  const workflowQuery = getQuery() + `&approval_list_page_id=${route.params.pageId}`;

  Promise.all([
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    currencyRes.then(res => {
      if (res.data) currencies.value = res.data
    }),
    businessRes.then(res=> {
      if(res.data) business.value = res.data
    }),
    costCenterRes.then(res=> {
      if(res.data) costCentres.value = res.data
    }),
    projectRes.then(res=> {
      if(res.data) projects.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    }),
    prepareCreateUpdatePageData()
  ])
  .then(() => {
    loader.value = false;
  })
  .catch((err) => {
    loader.value = false
  })
}

async function prepareCreateUpdatePageData() {
  try {
    if(route.query.poId)
    {
      const pOResp = await fetchSinglePO(route.query.poId, getQuery());
      poEditData.value = pOResp ? pOResp.data : {};
      setFormData()
      isPOCreate = false;
      return;
    }
    // Generate PO number
    formData.value.po_number = await fetchPONumber(getQuery());
    formData.value.po_date = new Date().toISOString().split('T')[0];
  } catch (e) {}
}

function setFormData() {
  headerTitle.value          = 'Update PO';
  supplier.value             = poEditData.value.contact_profile ? poEditData.value.contact_profile.full_name: null;
  formData.value.supplier_id = poEditData.value.contact_profile_id ?? null;
  formData.value.project_id  = poEditData.value.project_id ?? null;
  formData.value.po_date     = poEditData.value.po_date;
  formData.value.po_number   = poEditData.value.po_number;
  formData.value.description = poEditData.value.description;
  formData.value.workflow_approval_logs = poEditData.value.workflow_approval_logs;
  formData.value.status = poEditData.value.status;
  formData.value.adjustment_amount = poEditData.value.adjustment_amount;
  formData.value.carrying_cost = poEditData.value.carrying_cost;
  formData.value.tds = poEditData.value.tds;

  delete formData.value.item_details;
  delete formData.value.account_details;
  formData.value.item_details        = [];
  formData.value.account_details     = [];
  formData.value.requisition_details = [];
  formData.value.cs_details          = [];

  for(const item of poEditData.value.purchase_general) {
    if (item.is_product === '1') {
      formData.value.item_details.push(item);
      continue;
    }
    item.account_head_id = item.cs_general.requisition_account.account_head_id;
    formData.value.account_details.push(item);
  }
}

function removeBill(index) {
    if(hasItemDetails.value) {
        formData.value.item_details.splice(index, 1)
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.splice(index, 1)
    }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function onClickCloseButton() {
  delete route.query.poId;
  router.push({
    name: route.query.type === 'purchase-order-bill' ? 'purchase-order-bill' : 'po-list',
    params: route.params, query: route.query
  });
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function savePO() {
  handlePOSubmit();
}

function handlePOSubmit() {
  if(!formData.value.supplier_id) {
    showError("Supplier is required")
    return;
  }
  poData.company_id  = companyId.value;
  poData.supplier_id = formData.value.supplier_id;
  poData.cost_centre_id = formData.value.cost_centre_id;
  poData.business_id = formData.value.business_id;
  poData.project_id = formData.value.project_id;
  poData.po_number   = formData.value.po_number;
  poData.po_type     = "new_po";
  poData.po_date     = formData.value.po_date;
  poData.description = formData.value.description;
  poData.adjustment_amount = formData.value.adjustment_amount;
  poData.carrying_cost = formData.value.carrying_cost;
  poData.tds = formData.value.tds;

  poData.general = [];
  if (formData.value.has_item_detail) {
    formData.value.item_details.forEach((item) => {
      let data           = {};
      data.supplier_id   = formData.value.supplier_id;
      data.product_id    = item.product_id;
      data.is_product    = 1;
      data.po_number     = formData.value.po_number;
      data.quantity      = item.quantity;
      data.currency      = item.currency;
      data.rate          = item.rate;
      data.amount        = getAmount(item);
      data.vat           = item.vat;
      data.vat_amount    = item.vat_amount;
      data.discount_percent = item.discount_percent;
      data.discount_amount = item.discount_amount;
      data.total_amount  = getTotalAmount(item, data.amount, data.vat_amount,data.discount_amount);
      data.description   = item.description;


      if(! isPOCreate) {
        data.id    = item.id;
      }

      poData.general.push(data);
    })
  }
  else {
    formData.value.account_details.forEach((item) => {
      let data           = {};
      data.supplier_id   = formData.value.supplier_id;
      data.is_product    = 0;
      data.po_number     = formData.value.po_number;
      data.quantity      = 0;
      data.currency      = item.currency;
      data.rate          = 0.00;
      data.amount        = getAmount(item);
      data.vat           = item.vat;
      data.vat_amount    = item.vat_amount;
      data.total_amount  = getTotalAmount(item, data.amount, data.vat_amount);
      data.description   = item.description;

      if(! isPOCreate) {
        data.id    = item.id;
      }

      poData.general.push(data);
    })
  }
  poData.general = JSON.stringify(poData.general);
  if(isPOCreate) {
    createPO();
    return;
  }
  updatePO()
}

function createPO(redirect = true) {
  createNewPO(poData, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect) navigateToListPage()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function updatePO(redirect = true) {
  updatePOItem(poData, route.query.poId, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect) navigateToListPage()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function navigateToListPage() {
  delete route.query.poId;
  delete route.query.selected_requisitions;

  router.push({
    name: (route.query.type === 'purchase-order-bill')
           ? 'purchase-order-bill' : 'po-list',
    params: route.params, query: route.query
  });
}

const shouldSave = computed( () => {
  if(! formData.value.status) {
    return true;
  }

  return ['pending', 'returned'].includes(formData.value.status);
})

function getAmount(data) {
  if (data.is_product === "1") {
    return data.rate * data.quantity
  } else {
    return data.amount
  }
}

function getVatAmount(data, amount) {
  if(! data.vat) {
    return 0
  }
  if (data.vat && data.is_product === "1") {
    return (data.vat / 100 * amount).toFixed(2)
  }
  return data.vat / 100 * amount
}

function getTotalAmount(data, amount, vat_amount,discount_amount) {
  if (data.vat === 0) {
    return parseFloat(amount) - parseFloat(discount_amount)
  }

  return parseFloat(amount) + parseFloat(vat_amount) - parseFloat(discount_amount)
}

function addNewBill() {
    if(hasItemDetails.value) {
        formData.value.item_details.push({
            product_id  : null,
            quantity    : null,
            currency    : 'BDT',
            rate        : null,
            amount      : null,
            vat         : 0,
            vat_amount  : 0,
            total_amount: null,
            description : null,
        })
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.push({
            account_head_id: null,
            currency       : null,
            amount         : null,
            vat            : null,
            vat_amount     : 0,
            total_amount   : null,
            description    : null,
        })
    }
}

const itemsDetails    = computed(() => {
  return formData.value.item_details
})
const accountsDetails = computed(() => {
  return formData.value.account_details
})
const isItem = computed(() => {
    return !!formData.value.has_item_detail
})
const discountAmount = computed(() => formData.value.adjustment_amount);
const carryingCost = computed(() => formData.value.carrying_cost);
const tdsCost = computed(() => formData.value.tds);

const subTotal        = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.quantity && item.rate) {
        subTotal += parseFloat((item.quantity * item.rate) - item.discount_amount)
      }
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseFloat(item.amount)
      }
    })
  }

  return subTotal.toFixed(2)
})
const totalVAT = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat_amount) {
        vat += parseFloat(item.vat_amount)
      }
    })
    return vat.toFixed(2);
  }

  formData.value.account_details.map(item => {
    if (item.vat_amount) {
      vat += parseFloat(item.vat_amount)
    }
  })
  return vat.toFixed(2)
})
const total           = computed(() => {
  return (parseFloat(subTotal.value) + parseFloat(totalVAT.value)).toFixed(2)
})

watch(discountAmount, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    adjustmentTotal.value = total.value
    formData.value.adjustment_amount = 0;
    return;
  }

  if (newValue !== oldValue) {
    adjustmentTotal.value = total.value - discountAmount.value
  }
})

watch(carryingCost, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    grandTotal.value = parseFloat(adjustmentTotal.value) + parseFloat(tdsCost.value);
    formData.value.carrying_cost = 0;
    formData.value.tds = tdsCost.value;
    return;
  }

  if (newValue !== oldValue) {
    grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value) + parseFloat(tdsCost.value) ).toFixed(2);
  }
})

watch(tdsCost, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    grandTotal.value = parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value);
    formData.value.carrying_cost = carryingCost.value;
    formData.value.tds = 0;
    return;
  }

  if (newValue !== oldValue) {
    grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value) + parseFloat(tdsCost.value) ).toFixed(2);
  }
})

watch(total, () => {
  adjustmentTotal.value = total.value  - discountAmount.value;
})

watch(adjustmentTotal, () => {
    grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value) + parseFloat(tdsCost.value) ).toFixed(2);
})

watch(adjustmentTotal, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    formData.value.adjustment_amount = parseFloat((total.value - newValue).toFixed(4));
  }
})

onMounted(() => {
  onPageReload();
})
</script>


<style>
._card_action button{
  border: none !important;
}

._align_item_center{
  align-items: center;
}

._inline_input{
  width: 80%
}

._terms_card{
  width: 80%;
}

._card_row:last-child {
    border: none;
}

._card_row{
    border-color: #e5e5e5;
    border-style: solid;
    padding-bottom: 2%;
    margin-bottom: 1%;
    border-width: 0;
    border-bottom-width: 1px;
}
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}
</style>